import React from "react";

function Services() {

    return (
      
      <div id="services" className="text-center" >
        <div className="container" >
          <div className="section-title">
            <h2 style={{color:'#29a3a3', fontSize:52}}>Full Services Real Estate</h2>
            <p style={{fontSize:22}}>
              A trusted name in business for more than 10 years.
            </p>
          </div>
          
          </div>
        </div>

    );
  }

export default Services;
