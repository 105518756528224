import React, {useState} from 'react'
import 'firebase/database';
import { Axios, db } from '../firebase/firebaseConfig'
require("firebase/firestore");

 const Contact = () => {
  const [formData, setFormData] = useState({})
  const yay = 'Message sent. We\'ll get back to you as soon as possible.'
  const nay = 'We\'ll never share your email with anyone else.'
  const [sent, setSent] = useState(false)

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = event => {
    event.preventDefault()
    sendEmail()
    setSent(true)
    setFormData({
      name: '',
      email: '',
      message: '',
    })
  }
  const result =()=>{
    return sent? yay: nay;
  }
  const sendEmail = () => {
    Axios.post(
      'https://us-central1-americagold-689fc.cloudfunctions.net/submit',
      formData
    )
      .then(res => {
        db.collection('contacts').add({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          time: new Date(),
          
        })
      })
      .catch(error => {
        console.log(error)

      })
    }
  return (
    <>
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form name="sentMessage" method='post' id="contactForm" onSubmit={handleSubmit} >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name='name'
                          id="name"
                          value={formData.name || ''} onChange={updateInput}
                          className="form-control"
                          placeholder="Name"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="email"
                          type='email'
                          id="email"
                          value={formData.email || ''} onChange={updateInput}
                          className="form-control"
                          placeholder="Email"
                          required='required'
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      value={formData.message || ''} onChange={updateInput}
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required='required'

                    ></textarea>
                    
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                  <h5>{result()}</h5>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  Irvine, CA
                </p>
              </div>
              
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>
                  info@americagoldinc.com
                </p>
              </div>
            </div>
            
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2020 America Gold Finanical
              
            </p>
          </div>
        </div>
      </div>
    
    </>
  );
}
export default Contact
