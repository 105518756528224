import React from 'react'

function about() {
const list1 = ["A trusted team", "Best service", "Experience", "Friendly staff" ]
  const list2 = ["Local business", "Not just an online firm", "Working hard for you", "Try us to see yourself"]

    return (
      <>
        <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/about.jpg" className="img-responsive" alt="about"/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2 style={{color:'#29a3a3'}}>About Us</h2>
                <p style={{fontSize: 18}}>America Gold Financial specializes in residential property transactions, including buying and selling. Our team consists of experienced real estate agents, each with a focus on specific neighborhoods or property types. They leverage cutting-edge technology and data-driven marketing strategies to identify the best opportunities for clients and maximize property exposure."</p>
                <h3>Why Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                    {list1.map(i => (
                        <li key={i}>
                          {i}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                    
                    {list2.map(i => (
                        <li key={i}>
                          {i}
                        </li>
                      ))}
                    
                    </ul>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }

export default about
