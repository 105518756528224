import React from "react";
import Data from '../data/fData.json'


 function features() {

      const Datai = Data.map((data,i) =>(

            <div  key={`${data.title}-${i}`} className="col-xs-6 col-md-3" >
            {" "}
              <i className={data.icon}></i>

              <h3 style={{color:'#29a3a3'}}>{data.title}</h3>
              <p>{data.text}</p>
            </div>

          ))

    return (
      <>
      <div id="features" className="text-center">
       <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title" >
          <h2 style={{color:'#29a3a3'}}>Features</h2>
        </div>
        <div className="row" >
          <div >
              {Datai} 
            </div>
           </div>
         </div>
       </div>
      </>
    );
  }


export default features;
