import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore';
import Axios from 'axios'

  // Initialize Cloud Firestore through Firebase
firebase.initializeApp({
  apiKey: "AIzaSyDj6wQVKz5HyBEqfjWILaFstDUKFHvKi4w",
  authDomain: "americagold-689fc.firebaseapp.com",
  databaseURL: "https://americagold-689fc.firebaseio.com",
  projectId: "americagold-689fc",
  storageBucket: "americagold-689fc.appspot.com",
  messagingSenderId: "432226367975",
  appId: "1:432226367975:web:96e159b283061cc30d6542"
});

var db = firebase.firestore();
export { Axios, db }