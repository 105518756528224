import React from 'react'
import Navigation from './components/navigation';
import Header from './components/header';
import Features from './components/features';
import About from './components/about';
import Services from './components/services';
import Contact from './components/contact';

 function App(props) {

    return (
      <div>
        <Navigation />
        <Header  />
        <Features />
        <About />
        <Services  />
        <Contact />
      </div>
    )
  }

export default App;
